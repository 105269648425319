import { defineAsyncComponent } from 'vue';

const Login = defineAsyncComponent(() => import('@/pages/auth/login/Login.vue'));
const Register = defineAsyncComponent(() => import('@/pages/auth/register/Register.vue'));
const ForgotPassword = defineAsyncComponent(() => import('@/pages/auth/forgotPassword/ForgotPassword.vue'));
const GoogleAuthNameCorrection = defineAsyncComponent(() => import('@/pages/auth/GoogleAuthNameCorrection.vue'));
const ColaberrySignupForm = defineAsyncComponent(() => import('@/pages/auth/ColaberrySignupForm.vue'));
const ResetPassword = defineAsyncComponent(() => import('@/pages/auth/forgotPassword/ResetPassword.vue'));
const FormRegister = defineAsyncComponent(() => import('@/pages/auth/register/FormRegister.vue'));
const PartnerRegister = defineAsyncComponent(() => import('@/pages/auth/register/PartnerRegister.vue'));

export default [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth" },
  },
  {
    path: "/signup",
    redirect: "/register",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth" },
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth" },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth" },
  },
  {
    path: "/completedetails",
    name: "GoogleAuthNameCorrection",
    component: GoogleAuthNameCorrection,
    meta: { layout: "auth" },
  },
  {
    path: "/formSignup",
    name: "ColaberrySignupForm",
    component: ColaberrySignupForm,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth", partners: ["colaberry"] },
  },
  {
    path: "/formRegister",
    name: "CustomRegister",
    component: FormRegister,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth", partners: ["masterschool"] },
  },
  {
    path: "/business",
    name: "PartnerRegister", // empty partner needed for advice availability only
    component: PartnerRegister,
    meta: { public: true, redirectToAppIfAuthenticated: true, layout: "auth", partners: [""] },
  },
];
