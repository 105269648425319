import { createI18n } from 'vue-i18n'


function loadMessages() {
  // @ts-ignore
  const context = require.context(".././locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  context.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = context(key);
    }
  });
  return { context, messages };
}

const { context, messages } = loadMessages();

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_CONFIGS === "viralcv" ? "fr" : "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  // @ts-ignore
  messages,
});

// Hot updates
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter(locale => messages[locale] !== newMessages[locale])
      .forEach(locale => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}
export default i18n;
