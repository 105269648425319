<template>
  <div class="datePickerBody" :style="{ width: width }">
    <CalendarIcon />
    <v-menu
      ref="dateMenu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ props }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="label ? label : $t('Generic.Common.fill_date')"
          readonly
          v-bind="props"
          hide-details
          variant="underlined"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :max="maxDate"
        no-title
        color="primary"
        @update:modelValue="onDateSelected"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { CalendarIcon } from "@/assets/fonts/icons/index";

export default {
  components: {
    CalendarIcon,
  },
  props: {
    value: String,
    width: String,
    label: String,
    max: Date,
  },
  data() {
    return {
      date: this.parseDate(this.value),
      menu: false,  // Bind this to v-model of v-menu to control its open/close state
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    maxDate() {
      return this.max ? this.max.toISOString().slice(0, 10) : null;
    },
  },
  watch: {
    date(val) {
      this.$emit("input", this.formatDate(val));
    },
    value(val) {
      this.date = this.parseDate(val);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      if (typeof date === 'string' && date.includes("-")) {
        const [year, month, day] = date.split("-");
        return `${month}/${day}/${year}`;
      } else if (date instanceof Date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
      return date;
    },
    parseDate(date) {
      if (!date) return null;

      if (typeof date === 'string' && date.includes("/")) {
        const [month, day, year] = date.split("/");
        return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
      }
      return new Date(date);
    },
    onDateSelected() {
      this.menu = false;  // Close the menu
    },
  },
};
</script>

<style scoped>
.datePickerBody {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.datePickerBody .v-input {
  width: 50px;
  display: block;
}
</style>
